import Search from "@geist-ui/icons/search";
import { BaseTagData, TagData, TagifyRuntimeSettings } from "@yaireo/tagify";
import Tags from "@yaireo/tagify/dist/react.tagify";
import { useRef } from "react";
import { Button } from "sparkl-ui";

export const TagSelector = ({
  initValue,
  setTags,
}: {
  initValue: string;
  setTags: (tags: string[]) => void;
}) => {
  const tagifyRef = useRef<Tagify>();
  const tagifySettings: Tagify.TagifySettings = {
    classNames: {
      input: "tagify__input tag-selector",
      hasNoTags: "tagify--noTags-tag-selector",
    },
    duplicates: false,
    editTags: 1,
  };

  function addTag() {
    if (tagifyRef.current) {
      tagifyRef.current.addEmptyTag();
    }
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexShrink: 0,
        alignItems: "center",
      }}
    >
      <Tags
        tagifyRef={tagifyRef}
        settings={tagifySettings} // tagify settings object
        defaultValue={""}
        onChange={(e) => {
          let tags = [];
          try {
            for (const tag of JSON.parse(e.detail.value)) {
              //console.log(tag.value);
              tags.push(tag.value);
            }
            setTags(tags);
          } catch (e) {
            // No more tags remain
            setTags([]);
          }
        }}
      />
      {/*Make an HTML button, containing /public/images/add.svg as its icon */}
      <button onClick={addTag} className="add-tag-button" title="Search">
        <Search size={16} />
      </button>
    </div>
  );
};
