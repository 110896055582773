import { NextPage } from "next";
import { CSSProperties, memo, useEffect, useState } from "react";
import { useMediaQuery } from "sparkl-ui";
import { Model } from "../../utils/feed_types";
import { ModelSelector } from "./model_selector";
import { selectorWhiteList } from "./model_tags";
import { TagSelector } from "../tagify/feed_bar/tag_selector";
import { FeedTypeSelector, FeedType } from "./type_selector";

export type FeedBarSelectorType = "model" | "type" | "tag";

export type FeedBarSelectorConfig = Partial<
  Record<FeedBarSelectorType, string>
>;
export interface FeedBarConfig {
  initQuery: Record<string, string>;
  setQuery: (query: URLSearchParams) => void;
  selectorConfig: FeedBarSelectorConfig;
  topNavHeight: string | number;
}

export const _FeedBar: NextPage<FeedBarConfig> = ({
  initQuery,
  setQuery,
  selectorConfig,
  topNavHeight = 0,
}) => {
  // console.log("Feed bar re-rendering...");
  const [model, setModel] = useState<Model | "all">(
    (initQuery.model as Model) ?? "verdant"
  );
  const [type, setType] = useState<FeedType>(
    (initQuery.type as FeedType) ?? "New"
  );
  const [tags, setTags] = useState<string[]>([]);

  const upMD = useMediaQuery("md", { match: "up" });
  const isXS = useMediaQuery("xs");
  useEffect(() => {
    let query = new URLSearchParams(initQuery);
    if (model === "all") {
      query.delete("model");
    } else {
      query.set("model", model);
    }
    query.set("type", type);
    for (let tag of tags) {
      query.append("tag", tag);
    }
    //console.log(query.toString());
    setQuery(query);
    window.scrollTo(0, 0);
  }, [model, type, tags]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      id="feed-bar"
      className="no-scroll"
      style={{
        zIndex: 10,
        position: "sticky",
        top: topNavHeight,
      }}
    >
      <div
        id="feed-bar-content"
        // className="no-scroll"
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          maxWidth: "100vw",
          zIndex: 10,
          paddingTop: "0.38rem",
          paddingBottom: "0.38rem",
          gap: "0 0.5rem",
          height: "auto",
          position: "absolute",
          backgroundColor: "rgba(0,0,0,0.0)",
          top: 0,
          overflowX: "auto",
        }}
      >
        {selectorConfig.type !== undefined && (
          <FeedTypeSelector
            getPopupContainer={() => document.getElementById("feed-bar")}
            value={type}
            onChange={(type: FeedType) => {
              setType(type);
            }}
          />
        )}
        {selectorConfig.model !== undefined && (
          <ModelSelector
            getPopupContainer={() => document.getElementById("feed-bar")}
            value={model}
            onChange={(value) => setModel(value as Model | "all")}
            models={selectorWhiteList}
          />
        )}
        {selectorConfig.tag !== undefined && (
          <TagSelector initValue={selectorConfig.tag} setTags={setTags} />
        )}
      </div>
    </div>
  );
};

export const FeedBar = memo(_FeedBar);
