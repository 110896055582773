import { NextPage } from "next";
import { FinishedPrompt } from "../../utils/prompt_types";
import { Post } from "./post";

interface FeedPostProps {
  prompt: FinishedPrompt;
  admin?: boolean;
}

export const FeedPost: NextPage<FeedPostProps> = ({ prompt, admin }) => {
  return (
    <Post
      prompt={prompt}
      admin={admin}
      className={"masonry-post"}
      style={{ marginBottom: "0.5rem" }}
    />
  );
};
