import type { NextPage } from "next";
import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Masonry from "react-masonry-css";
import { useMediaQuery, useTheme } from "sparkl-ui";
import useSWRInfinite from "swr/infinite";
import {
  CuratedResponse,
  CuratedSuccess,
  FinishedPrompt,
  InProgressPrompt,
  PromptsFailure,
  PromptsResponse,
  PromptsSuccess,
} from "../utils/prompt_types";
import { fetcher } from "../utils/swr";
import { FeedPost } from "./prompt_image/feed_post";
import { FeedBar, FeedBarSelectorConfig } from "./selectors/feed_bar";

export interface FeedProps {
  fallbackPrompts?: Record<string, CuratedResponse[]>;
  initFeed?: FinishedPrompt[];
  feedbarConfig: FeedBarSelectorConfig;
  initQuery: Record<string, string>;
  admin?: boolean; //Admin feed has special affordances - prompts have rating buttons
  endMessage?: string;
}

function isOriginalQuery(
  initQuery: Record<string, string>,
  currentQuery: URLSearchParams
) {
  //check if they match, regardless of order
  const initKeys = Object.keys(initQuery);
  const currentKeys = Array.from(currentQuery.keys());
  if (initKeys.length !== currentKeys.length) {
    return false;
  }
  for (const key of initKeys) {
    if (!currentKeys.includes(key)) {
      return false;
    }
    if (initQuery[key] !== currentQuery.get(key)) {
      return false;
    }
  }
  return true;
}

export interface FeedGridProps {
  data: (PromptsFailure | CuratedSuccess | PromptsSuccess)[];
  query: URLSearchParams;
  size: number;
  setSize: (size: number) => void;
  admin?: boolean;
  endMessage?: string;
  topNavHeight?: string | number;
}

export const FeedGrid: React.FC<FeedGridProps> = ({
  data,
  query,
  size,
  setSize,
  admin,
  endMessage,
  topNavHeight,
}) => {
  // This is a four-step filter for unfinished or off-schema prompts
  const filteredPages = data.filter((page): page is PromptsSuccess => {
    return page.status !== "failure";
  });
  //console.log(filteredPages);
  const prompts: (InProgressPrompt | FinishedPrompt)[] = filteredPages.reduce(
    (acc: (InProgressPrompt | FinishedPrompt)[], page) => {
      return [...acc, ...page.prompts];
    },
    []
  );
  //console.log("prompts", prompts);
  const finishedPrompts = prompts.filter((prompt): prompt is FinishedPrompt => {
    return prompt.status === "done";
  });
  const filteredPrompts = finishedPrompts.filter((prompt) => {
    // The intention here is to filter out params created in the "old" way.
    return (
      typeof prompt.params === "object" &&
      prompt.outputs != null &&
      prompt.params &&
      prompt.params.sharedParams &&
      prompt.params.sharedParams.prompts != null
    );
  });

  const theme = useTheme();
  let oneColumnSize = theme.breakpoints.sm.max;
  let twoColumnSize = theme.breakpoints.md.max;
  let threeColumnSize = theme.breakpoints.xl.max;

  return (
    <InfiniteScroll
      dataLength={filteredPrompts.length} //This is important field to render the next data
      next={() => setSize(size + 1)}
      hasMore={filteredPages[filteredPages.length - 1].prompts.length > 10}
      loader={<h4>Loading...</h4>}
      endMessage={
        <p style={{ textAlign: "center" }}>
          <b>{endMessage ?? "Yay! You have seen it all"}</b>
        </p>
      }
      scrollThreshold={0.6}
      style={{
        minHeight: "90vh",
        position: "sticky",
        top: topNavHeight,
        paddingTop: "3rem",
      }}
    >
      <Masonry
        breakpointCols={{
          [oneColumnSize]: 1,
          [twoColumnSize]: 2,
          [threeColumnSize]: 3,
          default: 4,
        }}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {filteredPrompts.map((finishedPrompt) => (
          <FeedPost
            prompt={finishedPrompt}
            admin={admin}
            key={finishedPrompt.id}
          />
        ))}
      </Masonry>
    </InfiniteScroll>
  );
};

export const Feed: NextPage<FeedProps> = ({
  fallbackPrompts,
  initFeed,
  feedbarConfig,
  initQuery,
  admin,
  endMessage,
}) => {
  // Style hooks
  const theme = useTheme();
  const upMD = useMediaQuery("md", { match: "up" });
  const isXS = useMediaQuery("xs");
  const topNavHeight = upMD ? "5rem" : isXS ? "2rem" : "3rem";

  // Feed query state
  //console.log("initFeed", initFeed);
  const initSearchParams = new URLSearchParams(initQuery);
  const [query, setQuery] = useState(initSearchParams);

  const initResponse: PromptsResponse = {
    prompts: initFeed ?? [],
    status: "success",
  };

  // Fetching data
  const { data, size, setSize } = useSWRInfinite<
    CuratedResponse | PromptsResponse
  >(
    (index, previousPageData: CuratedResponse | PromptsResponse) => {
      const queryCopy = new URLSearchParams(query);
      //console.log("Query", queryCopy.toString());
      if (queryCopy.get("type") === "Popular" && !queryCopy.get("tag")) {
        // Curated feed - temporarily disabled if you have a tag selected
        if (
          previousPageData &&
          previousPageData.status !== "failure" &&
          (previousPageData as CuratedSuccess).cursor
        ) {
          queryCopy.set(
            "cursor",
            (previousPageData as CuratedSuccess).cursor.toString()
          );
        }
        return `/api/curated?${queryCopy.toString()}`;
      } else {
        // first page
        if (!previousPageData || previousPageData.status === "failure") {
          return `/api/prompts?${queryCopy.toString()}`;
        }

        //subsequent pages
        queryCopy.set(
          "before",
          previousPageData.prompts.slice(-1)[0].id.toString()
        );
        return `/api/prompts?${queryCopy.toString()}`;
      }
    },
    fetcher,
    {
      fallbackData: isOriginalQuery(initQuery, query)
        ? [initResponse]
        : undefined,
      revalidateIfStale: true,
      revalidateAll: true,
      revalidateOnFocus: false, //don't refresh the feed on looking away & back
      initialSize: 1,
      revalidateFirstPage: false,
    }
  );

  // Actual feed component renders here
  return (
    <div
      style={{
        height: "100%",
        display: "grid",
        alignContent: "start",
        paddingBottom: "4rem",
        position: "sticky",
        top: topNavHeight,
      }}
    >
      <FeedBar
        initQuery={initQuery}
        setQuery={setQuery}
        selectorConfig={feedbarConfig}
        topNavHeight={topNavHeight}
      />
      {!data || data[0].status === "failure" ? (
        <div
          style={{
            paddingTop: "3rem",
          }}
        >
          {" "}
          Loading...{" "}
        </div>
      ) : (
        <FeedGrid
          data={data}
          query={query}
          admin={admin}
          size={size}
          setSize={setSize}
          endMessage={endMessage}
        />
      )}
    </div>
  );
};
