import React from "react";
import { Select, SelectProps, Text } from "sparkl-ui";
import { New, Popular } from "../icons";
import { GenericSelector } from "./generic_selector";

export type FeedType = "New" | "Popular";
export const feedTypes = ["New", "Popular"];
export const feedTypeIcons = {
  New: <New />,
  Popular: <Popular />,
};

export interface FeedTypeSelectorProps extends SelectProps {
  value: FeedType;
  onChange: (value: any) => void;
}

export const FeedTypeSelector: React.FC<FeedTypeSelectorProps> = ({
  value,
  onChange,
  ...props
}) => {
  return (
    <GenericSelector
      value={value}
      placeholder="Type"
      onChange={onChange}
      {...props}
    >
      {feedTypes.map((feedType) => (
        <Select.Option value={feedType} key={feedType}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.38rem",
              // limit height to the line height of the text
              height: "1.5rem",
            }}
          >
            <div style={{ height: "1.5rem", width: "1.5rem" }}>
              {feedTypeIcons[feedType as FeedType]}
            </div>

            <Text font="1rem" b>
              {feedType}
            </Text>
          </div>
        </Select.Option>
      ))}
    </GenericSelector>
  );
};
