import type { GetServerSideProps, NextPage } from "next";
import { useRouter } from "next/router";
import { useContext, useEffect } from "react";
import { Feed } from "../components/feed";
import { useGenieDispatch } from "../components/genie/use_genie";
import { LightboxContext } from "../components/prompt_image/lightbox_context";
import { getPrompts } from "../utils/prompts";
import {
  CuratedResponse,
  FinishedPrompt,
  PromptsResponse,
} from "../utils/prompt_types";
import { getPermalinkMetadataForPrompt } from "./permalink_metadata";

export const getServerSideProps: GetServerSideProps = async ({
  query,
  req,
}) => {
  //check if request has a prompt query param
  const prompt = query.prompt;
  let permalinkPrompt: FinishedPrompt | null = null;
  let permalinkMetadata: Record<string, string> | null = null;
  if (prompt && typeof prompt === "string") {
    const prompt_id = parseInt(prompt);

    const results: PromptsResponse = await getPrompts(
      {
        completed: "true",
        id: prompt_id.toString(),
      },
      null
    );

    if (results.status == "success" && results.prompts.length > 0) {
      permalinkPrompt = results.prompts[0] as FinishedPrompt;
    }

    if (permalinkPrompt) {
      permalinkMetadata = getPermalinkMetadataForPrompt(permalinkPrompt);
    }
  }

  let prompts: CuratedResponse = await fetch(
    `${req.headers.host!.startsWith("localhost") ? "http" : "https"}://${
      req.headers.host
    }/api/curated?completed=true&model=verdant`
  ).then((res) => res.json());

  // Switch back to this when we fix:
  // https://github.com/dryadsystems/you_eye/issues/86
  /*
  let prompts: CuratedResponse = await getCache().get_curated(
    {
      completed: "true",
      model: "verdant",
    },
    true
  );
  */

  if (prompts.status !== "success") {
    console.error("Unable to fetch prompts", prompts);
  }

  return {
    props: {
      prompts: prompts.status === "success" ? prompts.prompts : [],
      permalinkPrompt,
      permalinkMetadata,
    },
  };
};

interface HomeProps {
  prompts: FinishedPrompt[];
  permalinkPrompt?: FinishedPrompt;
}

const Homepage: NextPage<HomeProps> = ({ prompts, permalinkPrompt }) => {
  const router = useRouter();
  const { openWithLightbox } = useContext(LightboxContext);
  const genieDispatch = useGenieDispatch();

  useEffect(() => {
    if (router.query.text && typeof router.query.text === "string") {
      genieDispatch({
        type: "setText",
        text: decodeURIComponent(router.query.text),
      });
      // Remove the text query param from the URL, but keep it in the params
      var newQuery = { ...router.query };
      delete newQuery.text;
      router.replace(
        {
          pathname: router.pathname,
          query: router.query,
        },
        {
          pathname: router.pathname,
          query: newQuery,
        },
        { shallow: true }
      );
    }
  }, [router.query.text, genieDispatch]);

  useEffect(() => {
    //get int from prompt query param
    if (router.query.prompt && typeof router.query.prompt === "string") {
      const promptId = parseInt(router.query.prompt as string);
      let imageId;
      if (router.query.image && typeof router.query.image === "string") {
        imageId = parseInt(router.query.image as string);
      }
      console.log("promptId", promptId);
      console.log("permalinkPrompt", permalinkPrompt);
      if (permalinkPrompt && promptId === permalinkPrompt.id) {
        console.log("opening permalink prompt");
        openWithLightbox(permalinkPrompt, imageId ?? 0)();
      }
    }
  }, [
    router.query.prompt,
    openWithLightbox,
    permalinkPrompt,
    router.query.image,
  ]);

  return (
    <>
      <Feed
        initFeed={prompts}
        initQuery={{ model: "verdant", completed: "true", type: "Popular" }}
        feedbarConfig={{ model: "verdant", tag: "", type: "Popular" }}
      />
    </>
  );
};

export default Homepage;
